import React, {memo, useEffect, useState} from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Category from "./category";
import {Redirect, useHistory, useLocation, useParams} from "react-router-dom";
import queryString from "query-string";
import { Stepped } from "./stepped";
import { Landing } from "./landing";
import { findByName } from "core/helpers/category";
import { stepCats } from "./stepCats";
import products from "generation/products.json";
import Skeleton from "react-loading-skeleton";
//
// web_available_1_test_withprice: { from: $web_available_1_test_withprice }
const PRODUCTS_QUERY = gql`
  query getCategoryProducts(
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
    $ark_web_available_1: String
    $ark_web_available_2: String
    $ark_web_available_4: String
    $ark_web_available_6: String
    $ark_web_available_14: String
    $categoryId: String
    $ark_closeout: String
    
  ) {
    products(
      pageSize: $pageSize
      sort: $sort
      currentPage: $currentPage
      filter: { 
      category_id: { eq: $categoryId }
      ark_web_available_1: { from: $ark_web_available_1 }
      ark_web_available_2: { from: $ark_web_available_2 }
      ark_web_available_4: { from: $ark_web_available_4 }
      ark_web_available_6: { from: $ark_web_available_6 }
      ark_web_available_14: { from: $ark_web_available_14 }
      ark_closeout: { eq: $ark_closeout}
       }
    ) {
      total_count
  
      items {
        attribute_set_id
        id
        sku
        step: custitemprima_config_steps
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

//const PRODUCTS_QUERY = gql`
//   query getCategoryProducts(
//     $sort: ProductAttributeSortInput
//     $pageSize: Int
//     $currentPage: Int
//     $categoryId: String
//   ) {
//     products(
//       pageSize: $pageSize
//       sort: $sort
//       currentPage: $currentPage
//       filter: { category_id: { eq: $categoryId } }
//     ) {
//       total_count
//       aggregations {
//         attribute_code
//         label
//         options {
//           label
//           value
//         }
//       }
//       items {
//         id
//         step: custitemprima_config_steps
//       }
//       page_info {
//         current_page
//         page_size
//         total_pages
//       }
//     }
//   }
// `;

const CategoryQueryContainer = (props) => {
  const location = useLocation();
  const [availabilityFilter, setAvailabilityFilter] = useState();
  const [clearanceFilter, setClearanceFilter] = useState();
  let search = queryString.parse(location.search);
  const history = useHistory();
  let sortForSteps = { position: "ASC" };

  let currentPage = search?.page ? parseInt(search.page) : 1;
  let pageSize = search?.pageSize ? parseInt(search.pageSize) : 36; // @TODO Fix this
  //let categoryId =
  let { queryProps } = props;

  //console.log("query Props", props);
  // console.log("clearanceFilter",clearanceFilter)
  // console.log("availabilityFilter",queryString.stringify(availabilityFilter).trim().length !== 0 )

  useEffect(() => {
   //window.location.reload()

  }, [props]);

  let updatedProps = { ...props };

  let cats = {};
  if (props?.catProps?.isStepped) {
    pageSize = 999;
    const name = props.toolbarProps.name;
    const thisCatSteps = stepCats[name];
    // Category Step
    if (thisCatSteps) {
      Object.keys(thisCatSteps).forEach((cs) => {
        // Category
        let thisStepCats = [];
        thisCatSteps[cs].split(",").forEach((c) => {
          // Category Data
          let cd = findByName(c.trim(), props.catList);
          thisStepCats.push(cd);
        });
        cats = { ...cats, [cs]: thisStepCats };
      });
    }
  }

  const { data,  loading,error, refetch } = useQuery(PRODUCTS_QUERY, {
    variables: {
      ...queryProps,
      currentPage: currentPage,
      pageSize: pageSize,
       ...availabilityFilter,
      ...clearanceFilter,

      /**
       * if category is stepped we use ASC sort by position else selected
       */

      sort: props?.catProps?.isStepped ? sortForSteps : props.sortSelected,
    },
  });

  // useEffect(() => {
  //   refetch()
  // }, [history.location]);




  if (data?.products.items) {
    data.products.items = data.products.items.map((pi) => {
      let item = products.default?.[pi?.id];
      if (item) {
        return { ...item, ...pi };
      }
    });
  }



  updatedProps.toolbarProps.totalCount = data?.products?.total_count || 0;
  updatedProps.toolbarProps.totalPages =
    data?.products?.page_info?.total_pages || 1;
  updatedProps.listingProps = data?.products?.items || [];
  let totalNumOfItems = data?.products?.total_count;
  if (error) {

    return <Redirect to="/" />;
  }

  let { isLanding, isStepped, children } = props.catProps;

  if (isLanding && children.length > 0) {
    return <Landing {...updatedProps} />;
  } else if (isStepped) {
    return <Stepped {...updatedProps} stepCats={cats} />;
  }



  return <Category  {...updatedProps} totalNumOfItems={totalNumOfItems} setAvailabilityFilter={setAvailabilityFilter} setClearanceFilter={setClearanceFilter} queryProps={queryProps} loading={loading} />;
};

export default CategoryQueryContainer;
